import React from "react";
import {Link} from "gatsby";
import Wave from "../sections/common/Wave";
import Hero from "../sections/info/Hero";

import PageWrapper from "../components/PageWrapper";
import Team from "../sections/info/Team";
import CTA from "../sections/CTA/CTA";
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/information.jpg'


const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          headerButton: (
            <>
              <Link to="/contact" className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
                Let's Get Started 
              </Link>
            </>
          ),
          footerStyle: "digma",
        }}>
        <SEO
          title="Information On Our Interactive Video Platform"
          description="Information on our Video Platform, Latest Features and News"
          image={metaImg}
        />            
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
              </li>
              </ol>
           </nav>
        </div>           
        <Hero />
        <Wave color="#F7F9FC" /> 
        <Team className="grey pt-13 pt-lg-12 pb-15 pb-lg-12" />
        <CTA className="bg-images" />

      </PageWrapper>
    </>
  );
};
export default PricingPage;
