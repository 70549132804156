import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "gatsby";

import timeline from "../../assets/image/features/segment.png";
import table from "../../assets/image/svg/contents.svg";
import bookmark from "../../assets/image/svg/bookmark.svg";
import share from "../../assets/image/svg/share.svg";
import info from "../../assets/image/svg/info.svg";


const TeamCard = ({ image, link, name, position, ...rest }) => {
  return (
    <>
      {/* Single Team */}
      <div className="card-container">
        <div className="text-center mb-10 mb-lg-5">
          <div className="card-image-container" style={{backgroundImage: `url(${image})`}}></div>
          <div className="content pl-10 pr-10 pb-0 pt-0">
            <p className="font-size-8 mb-1">{name}</p>
            <p className="h-25 font-size-5 font-weight-normal mb-10">{position}</p>
            <div className="row text-center justify-content-center justify-content-evenly">
            </div>
            <Link to={link} tabIndex="-1" className="sbottom w-100 h-55 btn btn-dodger-blue-2 rounded-5">Explore</Link>
          </div>
        </div>
      </div>
      {/* Single Team */}
    </>
  );
};
const Team = ({ className, ...rest }) => {
  const elSlider = useRef();

  const slickSettings = {
    autoplay: false,
    slidesToShow: 4,
    arrows: false,
    infinite: true,
    touchThreshold: 200,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };    
  const TeamData = [
    {
      image: table,
      link: "/info/cms",
      name: "CMS",
      position: "Digma has its own Content Management System allowing you to be in control.",
    },    
    {
      image: info,
      link: "/info/faq",
      name: "Frequently Asked Questions",
      position: "Find out quickly some frequent questions",
    },    
    {
      image: bookmark,
      link: "/info/features",
      name: "Features",
      position: "Digma will make your videos incomparable with an interactive player that is feature rich",
    },
    {
      image: timeline,
      link: "/info/news",
      name: "News",
      position: "Latest news about Interactive Videos",
    },
    {
      image: share,
      link: "/info/partners",
      name: "Partners",
      position: "White Label Digma's Saas Software Services. For resellers and agencies.",
    },    


  ];
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* section title */}
        <div className="row">
            <div className="col-12">
            <h3 className="font-size-9 text-digma-dark mb-10">Want to know more?</h3>
              <div className="testimonial-one">
                <Slider
                  ref={elSlider}
                  {...slickSettings}
                  css={`
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                >   
                {TeamData.map(({ image, name, link, icons, position }, index) => {
                  console.log(icons)
                  return (
                    <div key={index}>
                      <TeamCard image={image} link={link} name={name} icons={icons} position={position} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
